<template>
  <div class="notes-card">
    <img
      class="notes-card__info-icon"
      :src="infoIcon"
      alt="Info Icon"
      @mouseover="showInfo = true"
      @mouseleave="showInfo = false"
    />

    <div v-if="!showInfo">
      <section class="notes-card__section">
        <h2 class="notes-card__title">MUSIC ESSENCE</h2>
        <ul class="notes-card__description">
          <li class="notes-card__description-item" v-for="note in musicNotes" :key="note">&nbsp;{{ note }}</li>
        </ul>
      </section>
      <section class="notes-card__section">
        <h2 class="notes-card__title">TOP NOTES</h2>
        <ul class="notes-card__description">
          <li class="notes-card__description-item" v-for="note in topNotes" :key="note">&nbsp;{{ note }} </li>
        </ul>
      </section>
      <section class="notes-card__section">
        <h2 class="notes-card__title">MIDDLE NOTES</h2>
        <ul class="notes-card__description">
          <li class="notes-card__description-item" v-for="note in middleNotes" :key="note">&nbsp;{{ note }}</li>
        </ul>
      </section>
      <section class="notes-card__section">
        <h2 class="notes-card__title">BASE NOTES</h2>
        <ul class="notes-card__description">
          <li class="notes-card__description-item" v-for="note in baseNotes" :key="note">&nbsp;{{ note }}</li>
        </ul>
      </section>
    </div>

    <div v-else>
          <p class="notes-card__tooltip">
            Sound frequencies vary by input and environment, so the Liquid Sound algorithm may produce unique results each time, adding to the personalized experience.
          </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import infoIcon from '../assets/images/info.png';

const showInfo = ref(false);

const props = defineProps({
  musicNotes: {
    type: Array,
    required: true,
  },
  topNotes: {
    type: Array,
    required: true,
  },
  middleNotes: {
    type: Array,
    required: true,
  },
  baseNotes: {
    type: Array,
    required: true,
  },
});
</script>

<style scoped>
.notes-card {
  --notes-card-text-align: start;
  --notes-card-min-height: 300px;
  --notes-card-max-height: 300px;
  --notes-card-description-font-size: 1.25rem;

  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  min-height: var(--notes-card-min-height);
  max-height: var(--notes-card-max-height);
  width: 100%;
  background-color: #fff;
  text-align: var(--notes-card-text-align);
  opacity: 0.8;
  position: relative;
  overflow: hidden;
  color: #000;
}

@media (max-width: 992px) {
  .notes-card {
    --notes-card-text-align: center;
    --notes-card-description-font-size: 1rem;
    --notes-card-description-justify-content: center;
  }
}

.notes-card__tooltip {
  color: #000;
}

.notes-card__section {
  margin-bottom: 20px;
}

.notes-card__title {
  font-size: 1.125rem;
  font-weight: 400;
  color: #c4c4c4;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.notes-card__description {
  font-size: var(--notes-card-description-font-size);
  font-weight: 600;
  margin-top: 5px;
  color: #000;
  letter-spacing: 0.05em;
  font-family: OutfitLight;
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  list-style-type: none;
  padding: 0;
  justify-content: var(--notes-card-description-justify-content);
}

.notes-card__description-item {
  color: #000;
}

.notes-card__description-item::after {
  content: ',';
}

.notes-card__description-item:last-child::after {
  content: '';
}

.notes-card__description-item::marker {
  display: none;
}

.notes-card__info-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  transform: translate(-8px, 24px);
}
</style>
