<template>
  <div class="playlist">
    <div class="playlist__header">
      <LSHeader  :navigateTo="goBack" :url="goBackUrl"/>
    </div>

    <main class="playlist__main">
      <h1 class="playlist__title">PLAYLIST</h1>

      <BottleList :bottles="bottles" />

      <div class="playlist__sound-wave">
        <SoundWave variant="lg" />
      </div>
    </main>
  </div>
</template>

<script setup>
import BottleList from '../components/BottleList.vue';
import SoundWave from '../components/SoundWaves.vue';
import shibuya from '../assets/songs/shib.mp3';
import jung from '../assets/songs/jung.mp3';
import jungMov from '/public/movies/black.png';
import breeze from '../assets/songs/breeze.mp3';
import shibMov from '/public/movies/mirror.png';
import breezeMov from '/public/movies/white.png';
import { useRouter } from 'vue-router';
import LSHeader from '@/components/LSHeader.vue';

const bottles = [
  {
    name: 'Noise Pollution',
    topNotes: ['Birch Tar'],
    middleNotes: ['Labdanum', 'Black Pepper'],
    baseNotes: ['Patchouli'],
    musicNotes: ['F5', 'C#4', 'D#6', 'D8'],
    price: 130,
    edition: '01/1000',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: jung,
    movie: jungMov,
  },
  {
    name: 'Shibuya Crossing',
    topNotes: ['Amber'],
    middleNotes: ['Pink Pepper', 'Saffron'],
    baseNotes: ['Vanilla'],
    musicNotes: ['B4', 'E4', 'A#6', 'C#7'],
    price: 130,
    edition: '01/1000',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: shibuya,
    movie: shibMov,
  },
  {
    name: 'Namibian Breeze',
    topNotes: ['Rose'],
    middleNotes: ['Green Tea', 'Ylang-Ylang'],
    baseNotes: ['Cypress'],
    musicNotes: ['C#2', 'G#2', 'F3', 'C4'],
    price: 130,
    edition: '01/1000',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: breeze,
    movie: breezeMov,
  },
];
const router = useRouter();

const goBackUrl = '/main'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.playlist {
  --playlist-title-margin: 0 0 auto 0;
  --playlist-max-width: 70%;

  --playlist-sound-wave-position: initial;
  --playlist-sound-wave-bottom: initial;
  --playlist-sound-wave-left: initial;
  --playlist-sound-wave-transform: initial;
  --playlist-sound-wave-height: initial;
  --playlist-sound-wave-width: initial;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 48px);
  min-height: fit-content;
  max-width: var(--playlist-max-width);
  margin: auto;
  padding: 24px;
  color: var(--text-color);
  animation: playlist__fade-in 3s;
}

@media (max-width: 1200px) {
  .playlist {
    --playlist-title-margin: 60px 0;
  }
}

@media (max-width: 768px) {
  .playlist {
    --playlist-sound-wave-position: fixed;
    --playlist-sound-wave-bottom: 0;
    --playlist-sound-wave-left: 50%;
    --playlist-sound-wave-transform: translateX(-50%);
    --playlist-sound-wave-height: 150px;
    --playlist-sound-wave-width: 100%;
  }
}

@media (max-width: 576px) {
  .playlist {
    --playlist-max-width: initial;
  }
}

.playlist__title {
  margin: var(--playlist-title-margin);
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: var(--title-text-color);
  margin-bottom: 60px;
}

.playlist__main {
  margin: auto;
  width: 100%;
}

.playlist__header {
  margin-bottom: auto;
  width: 100%;
}

.playlist__sound-wave {
  margin: 0 0 auto 0;
  opacity: 0.1 !important;
  position: var(--playlist-sound-wave-position);
  bottom: var(--playlist-sound-wave-bottom);
  left: var(--playlist-sound-wave-left);
  transform: var(--playlist-sound-wave-transform);
  height: var(--playlist-sound-wave-height);
  width: var(--playlist-sound-wave-width);
}

@keyframes playlist__fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
