<template>
  <header class="ls-header">
    <Navigator @click="handleNavCallback" :navigateTo="navigateTo" :url="url" />
    <Wave size="sm" />
  </header>
</template>

<script setup>
import Wave from '../components/Wave.vue';
import Navigator from '@/components/Navigator.vue';

const { navigateTo, url, navCallback } = defineProps(['navigateTo', 'url', 'navCallback'])

const handleNavCallback = () => {
  navCallback && navCallback();
}
</script>

<style scoped>
.ls-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  color: var(--text-color);
}
</style>
