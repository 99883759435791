import { defineStore } from 'pinia';

export const useAudioStore = defineStore('audio', {
    state: () => ({
        audioData: null,
    }),
    actions: {
        setAudioData(audio) {
            this.audioData = audio;
        }
    },
    getters: {
        getAudioData: (state) => state.audioData,
    }
});
