<template>
  <div class="record">

    <LSHeader :navigate-to="goToMainPage" :url="goBackUrl"/>

    <section class="record__content">
      <h1 id="create-fragrance" class="record__title">
        <span class="span-fade-in">CREATE</span>
        <span class="span-fade-in">&nbsp;YOUR</span>
        <span class="span-fade-in">&nbsp;FRAGRANCE</span>
      </h1>
      <Record ref="record" />
    </section>

    <footer class="record__footer">
      <p>
        To upload music, let's collaborate responsibly. Ensure all licenses are in
        order before applying. Join our creative community and apply today to
        start transforming sound into scents.
      </p>
    </footer>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Record from '@/components/Record.vue';
import { ref } from 'vue';
import LSHeader from '@/components/LSHeader.vue';

const router = useRouter();
const record = ref(null);

const stopRecording = () => {
  record?.value?.stopRecording?.();
};

const goBackUrl = '/main'

function goToMainPage() {
  router.push(goBackUrl);
  stopRecording();
}
</script>

<style scoped>
.record {
  --record-padding: initial;
  --record-max-width: 70%;

  --record-footer-max-width: 65%;
  --record-footer-font-size: 1vmax;
  --record-footer-margin: 40px auto 0;

  --record-title-margin: initial initial 40px;
  --record-title-font-size: 3.3vmax;

  text-align: center;
  max-width: var(--record-max-width);
  padding: var(--record-padding);
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--text-color);
}

@media (max-width: 992px) {
  .record {
    --record-padding: 0;
    --record-max-width: 85%;

    --record-footer-max-width: 100%;
    --record-footer-font-size: 1.5vmax;
    --record-footer-margin: 0 0 36px;

    --record-title-margin: auto auto 0;
  }
}

@media (max-width: 576px) {
  .record {
    --record-title-font-size: 1.3vmax;
  }
}

.record__content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.record__title {
  font-size: var(--record-title-font-size);
  margin: var(--record-title-margin);
}

.record__footer {
  color: var(--text-color);
  max-width: var(--record-footer-max-width);
  margin: var(--record-footer-margin);
  font-size: var(--record-footer-font-size);
  font-family: OutfitLight;
}
</style>
