<template>
  <main ref="mainContent" class="main">
    <!-- <SubscriptionModal v-if="showSubscriptionModal" @setShowSubscriptionModal="setShowSubscriptionModal" /> -->

    <header class="main__header">
      <Wave :no-margin="true" :size="logoSize" class="main__icon" />
    </header>

    <div class="main__btn-group">
      <MainButton
        class="main__btn main__btn--primary"
        @click="navigateToLanding"
      >
        <span class="main__btn-text span-fade-in">Create</span>
      </MainButton>

      <MainButton
        variant="outline"
        class="main__btn main__btn--outline"
        @click="displayPlaylist"
      >
        <span class="main__btn-text">store</span>
      </MainButton>

      <MainButton
        variant="outline"
        class="main__btn main__btn--outline"
        @click="displayAbout"
      >
        <span class="main__btn-text">about</span>
      </MainButton>
    </div>
  </main>
</template>

<script setup>
import { useRouter } from 'vue-router';
import MainButton from '../components/MainButton.vue';
import SubscriptionModal from '../components/SubscriptionModal.vue';
import Wave from '../components/Wave.vue';
import { onUnmounted, ref, onMounted } from 'vue';

const router = useRouter();
const showSubscriptionModal = ref(false);
const mainContent = ref(null);
const getLogoSize = () => (window.innerWidth <= 992 ? 'md' : '');
const logoSize = ref(getLogoSize());
const setLogoSize = () => {
  logoSize.value = getLogoSize();
};

// const getVariant = () => {
//   return isMobile ? 'full' : 'lg'
// }

// const setAllDescendants = (inert) => {
//   const allMainContentDescendants =  mainContent.value.querySelectorAll('*');
//   allMainContentDescendants.forEach(descendant => {
//     descendant.inert = inert;
//   });
// }

// const subscriptionModalTimeout = setTimeout(() => {
//   setAllDescendants(false)

//   setShowSubscriptionModal(true);
// }, 5000)

// const setShowSubscriptionModal = (showState) => {
//   if(showState === false) {
//     setAllDescendants(false)
//   }

//   showSubscriptionModal.value = showState;
// }

// onUnmounted(() => clearTimeout(subscriptionModalTimeout))

onMounted(() => {
  window.onresize = setLogoSize;
});

onUnmounted(() => {
  window.onresize = null;
});

function navigateToLanding() {
  router.push('/analyze');
}

function displayPlaylist() {
  router.push('/playlist');
}

function displayAbout() {
  router.push('/behind');
}
</script>

<style scoped>
.main {
  --main-max-width: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: fit-content;
  min-width: 300px;
  padding: 24px;
  max-width: var(--main-max-width);
  margin: auto;
  overflow: auto;
}

@media (max-width: 992px) {
  .main {
    --main-max-width: unset;
  }
}

.main__header {
  height: 150px;
}

.main__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.main__btn-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto auto auto auto;
}

.main__btn-text {
  letter-spacing: 2px;
}

.main__btn {
  animation: main-button-fade-in 1.5s;
}

@keyframes main-button-fade-in {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
    scale: 1.3;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
</style>
