<template>
  <div class="modal-content">
    <section v-if="!showForm" class="content-initial">
      <p class="modal-title">Our online store is coming soon.</p>
      <p class="modal-description">
        Pre-order your LiquidSound today<br />
        and secure your exclusive access to the future of scent.
      </p>
      <button class="btn" @click="showForm = true">Pre-order</button>
    </section>

    <section v-else class="content-form">
      <input class="input" placeholder="E-mail" v-model="email" type="email" />
      <input class="input" placeholder="Name" v-model="name" type="text" />
      <button class="btn" @click="submitPreOrder">Pre-Order</button>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import emailjs from '@emailjs/browser';

const showForm = ref(false);
const email = ref('');
const name = ref('');

const generateCSV = () => {
  const csvContent = "Email,Name\n" + `${email.value},${name.value}\n`;
  return btoa(csvContent);
};

const sendEmail = () => {
  const serviceID = 'service_2hyma5s';
  // const serviceID = 'service_lsnsvwt';
  // const templateID = 'template_v6ttylo';
  const templateID = 'template_mded7d7';
  const userID = 'wIweFqJqqcdrC7OkG';

  const templateParams = {
    from_name: name.value,
    from_email: email.value,
  };

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then(response => {
      email.value = '';
      name.value = '';
      close();
    })
    .catch(error => {
      console.error('Error sending feedback:', error);
      alert('Failed to send feedback. Please try again.');
    });
};

const emit = defineEmits(['close']);

const close = () => {
  emit('close');
  showForm.value = false;
};

const submitPreOrder = () => {
  sendEmail()
};
</script>

<style scoped>
.modal-content {
  --modal-content-width: 500px;
  --modal-description-font-size: 1rem;
  --modal-content-padding: 40px;
  --modal-title-font-size: 1.5rem;

  background: #777;
  min-width: 300px;
  min-height: 200px;
  max-width: 90vw;
  text-align: center;
  padding: var(--modal-content-padding);
  width: var(--modal-content-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid white;
}

@media (max-width: 576px) {
  .modal-content {
    --modal-content--width: 90vw;
    --modal-content-padding: 20px;
    --modal-description-font-size: 0.625rem;
    --modal-title-font-size: 1.125rem;
  }
}

.content-initial {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-size: var(--modal-title-font-size);
  color: white;
  margin-bottom: 10px;
}

.modal-description {
  font-size: var(--modal-description-font-size);
  color: white;
  margin-bottom: 20px;
}

.btn {
  background: transparent;
  border: 2px solid white;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 12px;
  max-width: fit-content;
  margin: auto;
  margin-top: 20px;
}

.content-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.input {
  background: transparent;
  border: 2px solid white;
  padding: 10px;
  color: white;
  text-align: center;
  width: 100%;
  border-radius: 12px;
}

.input::placeholder {
  color: white;
  opacity: 1;
}
</style>
