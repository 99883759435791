<template>
  <div class="record__controls">
    <div class="record__button-wrapper">
      <RecordBtn
        ref="recordBtn"
        @recording-stopped="handleRecordingStop"
        @recording-reset="handleRecordingReset"
        @recording-started="handleRecordingStarted"
      />
    </div>

    <button class="record__reset" v-if="isStopped" @click="resetRecording">
      MAKE NEW RECORD
    </button>
    <button class="record__record-new" v-if="!isStopped && !isRecording" @click="startRecording">
      CLICK TO RECORD
    </button>
    <MainButton v-if="isStopped" @click="createFragrance">
        <span class="span-fade-in">CREATE</span>
        <span class="span-fade-in"> &nbsp; FRAGRANCE</span>
    </MainButton>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import MainButton from './MainButton.vue';
import RecordBtn from './RecordBtn.vue';
import { ref } from 'vue';
const recordBtn = ref(null);
const isStopped = ref(false);
const isRecording = ref(false);
const router = useRouter();
import { useAudioStore } from '@/store/audioStore';
const audioStore = useAudioStore();

const resetRecording = () => {
  recordBtn?.value?.resetRecording?.();
  isRecording.value = false;
};

const startRecording = () => {
  isRecording.value = true;
  recordBtn?.value?.startRecording?.();
};

const stopRecording = () => {
  recordBtn?.value?.stopRecording?.();
};

const handleRecordingStarted = () => {
  isRecording.value = true;
};

const handleRecordingReset = () => {
  isStopped.value = false;
};

const createFragrance = () => {
  const audioData = audioStore?.getAudioData;
  if (!audioData?.size) return;

  router.push('/liqufy');
};

const handleRecordingStop = () => {
  isStopped.value = true;
};

defineExpose({ stopRecording });
</script>

<style scoped>
.record__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.record__controls * {
  -webkit-tap-highlight-color: transparent;
}

.record__record-new, .record__reset {
  background: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: var(--text-color);
}

.record__reset, .record__button-wrapper {
  margin-bottom: 40px;
}
</style>
