<template>
  <div class="options-card">
    <fieldset class="options-card__list">
      <legend class="options-card__title">BOTTLE</legend>
      <label
        :class="option.class"
        v-for="(option, index) in bottleOptions"
        :key="index"
        :for="option.class"
      >
        <input type="radio" :id="option.class" :value="option" v-model="selectedBottle" name="bottle" />
        {{ option.label }}
      </label>
    </fieldset>

    <fieldset class="options-card__list">
      <legend class="options-card__title">CAPS</legend>
      <label
        :class="option.class"
        v-for="(option, index) in capOptions"
        :key="index"
        :for="option.class"
      >
        <input
          type="radio"
          :id="option.class"
          :value="option"
          v-model="selectedCap"
          name="cap"
        />
        {{ option.label }}
      </label>
    </fieldset>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import whiteSilver from '../assets/images/bottleVariants/whiteSilver.png';
import whiteGold from '../assets/images/bottleVariants/whiteGold.png';
import whiteBlack from '../assets/images/bottleVariants/whiteBlack.png';

import blackSilver from '../assets/images/bottleVariants/blackSilver.png';
import blackGold from '../assets/images/bottleVariants/blackGold.png';
import blackBlack from '../assets/images/bottleVariants/blackBlack.png';

import silverSilver from '../assets/images/bottleVariants/silverSilver.png';
import silverGold from '../assets/images/bottleVariants/silverGold.png';
import silverBlack from '../assets/images/bottleVariants/silverBlack.png';

const bottleOptions = ref([
  {
    label: 'Matte White',
    color: '#FFFFFF',
    class: 'matteWhite',
    id: 'whiteBtl',
  },
  {
    label: 'Matte Black',
    color: '#000000',
    class: 'matteBlack',
    id: 'blackBtl',
  },
  {
    label: 'Silver Mirror',
    color: '#C1C1C1',
    class: 'silverMirror',
    id: 'silverBtl',
  },
]);

const capOptions = ref([
  {
    label: 'Silver',
    color: '#C0C0C0',
    class: 'bottleCapSilver',
    id: 'silverCap',
  },
  { label: 'Gold', color: '#FFD700', class: 'bottleCapGold', id: 'goldCap' },
  { label: 'Black', color: '#000000', class: 'bottleCapBlack', id: 'blackCap' },
]);

const images = {
  'whiteBtl-silverCap': whiteSilver,
  'whiteBtl-goldCap': whiteGold,
  'whiteBtl-blackCap': whiteBlack,
  'blackBtl-silverCap': blackSilver,
  'blackBtl-goldCap': blackGold,
  'blackBtl-blackCap': blackBlack,
  'silverBtl-silverCap': silverSilver,
  'silverBtl-goldCap': silverGold,
  'silverBtl-blackCap': silverBlack,
};

const emit = defineEmits(['update:selectedCombination']);

const selectedBottle = ref(bottleOptions.value[0]);
const selectedCap = ref(capOptions.value[0]);

const selectedImage = computed(() => {
  const key = `${selectedBottle.value.id}-${selectedCap.value.id}`;
  // console.log(key);
  return images[key] || 'path/to/default.jpg';
});

watch([selectedBottle, selectedCap], ([bottle, cap]) => {
  const selectedCombination = { bottle, cap };
  // console.log(selectedImage.value);
  emit('update:selectedCombination', selectedImage.value);
});
</script>

<style scoped>
.options-card {
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  max-width: 320px;
  display: flex;
  justify-content: space-around;
  opacity: 0.8;
  width: 100%;
  box-sizing: border-box;
}

.options-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  letter-spacing: 2px;
  color: #06001c;
  font-weight: 600;
}

.options-card__list {
  display: flex;
  border: none;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

label {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  color: #06001c;
}

.option-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
}

input::placeholder {
  font-family: Outfit, sans-serif;
}

input[type='checkbox']:not(:checked):not(:disabled),
input[type='radio']:not(:checked):not(:disabled) {
  appearance: none;
  padding: 8px;
  margin-bottom: 0;
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  appearance: none;
  padding: 8px;
  margin-bottom: 0;
  border-radius: 50%;
  border: -30px transparent solid;
  position: relative;
  margin-right: 12px;
}

input[type='radio']:checked::before {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border: 2px solid black;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 2;
}

input[type='checkbox']:not(:checked):not(:disabled),
input[type='radio']:not(:checked):not(:disabled) {
  appearance: none;
  padding: 8px;
  margin-bottom: 0;
  border-radius: 50%;
  border: -30px transparent solid;
  position: relative;
  margin-right: 12px;
}

input::after {
  content: '';
  position: absolute;
  top: -7px;
  left: -7px;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border: 2px solid #e6e6e6;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
}

input[type='checkbox']:not(:checked):not(:disabled) {
  border-radius: 2px;
}

input[type='radio']:not(:checked):not(:disabled) {
  border-radius: 100px;
}

* {
  font-family: Outfit, sans-serif;
}

label {
  cursor: pointer;
  user-select: none;
  font-family: OutfitLight, sans-serif;
}

.matteWhite input[type='checkbox']:checked,
.matteWhite input[type='radio']:checked,
.matteWhite input[type='checkbox']:not(:checked):not(:disabled),
.matteWhite input[type='radio']:not(:checked):not(:disabled) {
  background: #ffffff;
}

.matteBlack input[type='checkbox']:checked,
.matteBlack input[type='radio']:checked,
.matteBlack input[type='checkbox']:not(:checked):not(:disabled),
.matteBlack input[type='radio']:not(:checked):not(:disabled) {
  background: #202224;
}

.silverMirror input[type='checkbox']:checked,
.silverMirror input[type='radio']:checked,
.silverMirror input[type='checkbox']:not(:checked):not(:disabled),
.silverMirror input[type='radio']:not(:checked):not(:disabled) {
  background: #c1c1c1;
}

.mattePink input[type='checkbox']:checked,
.mattePink input[type='radio']:checked,
.mattePink input[type='checkbox']:not(:checked):not(:disabled),
.mattePink input[type='radio']:not(:checked):not(:disabled) {
  background: #ffc0cb;
}

.matteClear input[type='checkbox']:checked,
.matteClear input[type='radio']:checked,
.matteClear input[type='checkbox']:not(:checked):not(:disabled),
.matteClear input[type='radio']:not(:checked):not(:disabled) {
  background: #dee4e9;
}

.bottleCapSilver input[type='checkbox']:checked,
.bottleCapSilver input[type='radio']:checked,
.bottleCapSilver input[type='checkbox']:not(:checked):not(:disabled),
.bottleCapSilver input[type='radio']:not(:checked):not(:disabled) {
  background: #b0b1b5;
}

.bottleCapGold input[type='checkbox']:checked,
.bottleCapGold input[type='radio']:checked,
.bottleCapGold input[type='checkbox']:not(:checked):not(:disabled),
.bottleCapGold input[type='radio']:not(:checked):not(:disabled) {
  background: #bf9b30;
}

.bottleCapBlack input[type='checkbox']:checked,
.bottleCapBlack input[type='radio']:checked,
.bottleCapBlack input[type='checkbox']:not(:checked):not(:disabled),
.bottleCapBlack input[type='radio']:not(:checked):not(:disabled) {
  background: #040404;
}
</style>
