<template>
  <transition name="fade">
    <div class="overlay" v-show="visible" @click.self="close">
      <div class="overlay-content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue';

defineProps({
  visible: Boolean,
});
const emit = defineEmits(['close']);

const closing = ref(false);

const close = () => {
  closing.value = true;
  setTimeout(() => {
    emit('close');
    closing.value = false;
  }, 300);
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
</style>
