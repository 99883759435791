<template>
  <button
    :class="`${variant}-button ${getFullWidthModifier()}`">
    <slot class="main-button-content"></slot>
  </button>
</template>

<script setup>
const { variant, fullWidth } = defineProps({
  variant: {
    required: false,
    default: 'solid',
    type: String,
  },
  fullWidth: {
    required: false,
    default: false,
    type: Boolean,
  },
})

const getFullWidthModifier = () => {
  const fullWidthModifier = `${variant}-button--full-width`
  return fullWidth ? fullWidthModifier : ''
}

</script>

<style scoped>
.outline-button, .solid-button {
  --btn-border-color: white;
  --btn-padding: 1.125rem 3rem;
  --btn-text-size: 1.125rem;
  --btn-border-radius: 999px;

  --solid-btn-bg-color: white;
  --solid-btn-text-color: #06001C;
  --solid-btn-text-hover-color: white;
  --solid-btn-text-inverted-color: #06001C;
  
  --outline-btn-hover-fill: white;
  --outline-btn-text-hover-color: #06001C;
  --outline-btn-text-color: white;

  --anim-resize-duration: 1.5s;
  --btn-transition: color 0.3s ease, transform 0.3s ease;
}

@media (pointer: coarse) {
  .outline-button, .solid-button {
    --btn-padding: 1.125rem 3rem;
  }
}

.outline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: var(--btn-padding);
  background-color: transparent;
  border-radius: var(--btn-border-radius);
  border: 2px solid var(--btn-border-color);
  font-size: var(--btn-text-size);
  cursor: pointer;
  overflow: hidden;
  width: auto;
  max-height: 48px;
  transition: var(--btn-transition);
  animation: resize var(--anim-resize-duration);
  text-transform: uppercase;
  text-wrap: nowrap;
  color: var(--outline-btn-text-color);
}

.outline-button:hover {
  color: var(--outline-btn-text-hover-color) !important;
}


.outline-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: var(--outline-btn-hover-fill);
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.outline-button:hover *  {
  color: var(--outline-btn-text-hover-color);
}

.outline-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.solid-button {
  position: relative;
  padding: var(--btn-padding);
  background-color: var(--solid-btn-bg-color);
  color: var(--solid-btn-txt-color);
  border-radius: var(--btn-border-radius);
  border: none;
  font-size: var(--btn-text-size);
  cursor: pointer;
  overflow: hidden;
  width: auto;
  max-height: 48px;
  transition: var(--btn-transition);
  z-index: 999;
  animation: resize var(--anim-resize-duration);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.solid-button * {
  color: var(--solid-btn-text-color);
}

.solid-button:hover *  {
  color: var(--solid-btn-text-hover-color);
}

.main-button-content {
  position: absolute;
  z-index: 2;
}

.solid-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background: var(--solid-btn-text-inverted-color);
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.solid-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.solid-button:hover {
  color: var(--solid-btn-text-hover-color);
}

.solid-button--full-width,
.outline-button--full-width {
  width: 100%;
}

@keyframes resize {
  0% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
</style>
