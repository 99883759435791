<template>
  <div id="app">
    <div class="video-overlay" :class="{ active: isTransitioning }"></div>

    <video
      id="bgVideo1"
      class="ls-logo__video"
      playsinline
      autoplay
      muted
      loop
      ref="videoElement1"
      :class="{ transitioning: isTransitioning && activeVideo === 2, active: isMainActive }"
    >
      <source :src="videoSrc1" type="video/mp4" />
    </video>

    <video
      id="bgVideo2"
      class="ls-logo__video"
      playsinline
      autoplay
      muted
      loop
      ref="videoElement2"
      :class="{ transitioning: isTransitioning && activeVideo === 1, active: !isMainActive }"
    >
      <source :src="videoSrc2" type="video/mp4" />
    </video>

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const videoElement1 = ref(null);
const videoElement2 = ref(null);

const isTransitioning = ref(false);
const activeVideo = ref(1);

const paths = {
  mainDesktop: "/movies/backgroundDesktop.mp4",
  mainMobile: "/movies/backgroundMobile.mp4",
  secondDesktop: "/movies/hzBg.mp4",
  secondMobile: "/movies/Vertical_Background_B.mp4",
};

const isMobile = window.innerWidth <= 576;
const mainVideo = isMobile ? paths.mainMobile : paths.mainDesktop;
const secondVideo = isMobile ? paths.secondMobile : paths.secondDesktop;

const videoSrc1 = ref(mainVideo);
const videoSrc2 = ref(secondVideo);

const videoSrc = computed(() => {
  if (route.fullPath === "/main" || route.fullPath === "/") {
    return mainVideo;
  }

  return secondVideo;
});

const isMainActive = computed(() => {
  return route.fullPath === "/main" || route.fullPath === "/";
})

watch(videoSrc, (newSrc) => {
  const nextVideo = activeVideo.value === 1 ? videoElement2.value : videoElement1.value;
  const currentVideo = activeVideo.value === 1 ? videoElement1.value : videoElement2.value;

  if (!nextVideo || !currentVideo) return;

  isTransitioning.value = true;

  nextVideo.src = newSrc;

  nextVideo.addEventListener(
    "loadeddata",
    () => {
      nextVideo.play().catch((err) => console.error("Play failed:", err));
      activeVideo.value = activeVideo.value === 1 ? 2 : 1;
      isTransitioning.value = false;
    },
    { once: true }
  );
});
</script>


<style>
:root {
  --title-text-color: white;
  --text-color: white;
  --input-text-color: #06001c;
}

body, html, #app {
  padding: 0;
  margin: 0;
  height: 100%;
}

#app {
  height: 100%;
  min-height: fit-content;
}

* {
  font-family: Outfit;
  animation: global-fade-in 0.5s;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

img,
video,
iframe {
  max-inline-size: 100%;
  block-size: auto;
}

#bgVideo.transitioning {
  opacity: 0;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -2;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.video-overlay.active {
  opacity: 1;
}

#bgVideo1,
#bgVideo2 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 101%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  transition: opacity 2.5s ease;
  opacity: 0;
}

#bgVideo1.active {
  opacity: 1;
}

#bgVideo2.active {
  opacity: 1;
}

#bgVideo1.transitioning,
#bgVideo2.transitioning {
  opacity: 0; 
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -2;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.video-overlay.active {
  opacity: 1; /* Fade in overlay during transition */
}

@font-face {
  font-family: Outfit;
  src: url('./assets/fonts/astonpoliz/Astonpoliz.ttf') format('opentype');
}

@font-face {
  font-family: OutfitLight;
  src: url('./assets/fonts/astonpoliz/Astonpoliz.ttf') format('opentype');
}

@font-face {
  font-family: OutfitRegular;
  src: url('./assets/fonts/astonpoliz/Astonpoliz.ttf') format('opentype');
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  unicode-bidi: isolate;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 6px;
  font-family: Outfit;
}

a {
  font-family: OutfitLight, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.mainBtnOutlineContent {
  font-family: OutfitLight, sans-serif;
  font-weight: 400;
}

.mainBtnContent {
  position: relative;
  font-family: OutfitLight, sans-serif;
  font-weight: 400;
  z-index: 99999;
  margin: 0;
  letter-spacing: 2px;
}

@keyframes global-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

.odometer * {
  opacity: 1 !important;
}

.odometer .odometer-value {
  margin-left: -2.3px;
  font-family: OutfitLight, sans-serif;
  width: 19px;
}

video {
  pointer-events: none;
  user-select: none;
}

@media (max-width: 992px) {
  * {
    user-select: none;
    outline: none;
  }
}
</style>
