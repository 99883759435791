<template>
  <form class="title-color-card">
    <input
      aria-label="Input to name your perfume"
      :value="bottleTitle"
      @input="handleTitleChange"
      class="title-color-card__title-input"
      type="text"
    />

    <chrome v-model="selectedColor" class="title-color-card__custom-chrome-picker" />
    <div class="title-color-card__buy-button">
      <MainButton @click="goToThankYou">
          <span class="span-fade-in">PURCHASE</span>
          <span class="span-fade-in">&nbsp;NOW</span>
      </MainButton>
    </div>

  </form>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { Chrome } from '@ckpack/vue-color';
import { useRouter } from 'vue-router';
import '../styles/titleAndColorCard.css';
import MainButton from '../components/MainButton.vue';

const bottleTitle = ref('Name Your Fragrance');
const selectedColor = ref('#FF0000');
const router = useRouter();
const emit = defineEmits(['handleTitleChange', 'handleColorChange']);

onMounted(() => {
  const colorSlider = document.querySelector('.vc-hue-container')
  colorSlider.setAttribute('aria-label', 'Input to change color of your perfume')
}) 

const handleTitleChange = e => {
  bottleTitle.value = e.target.value;
  emit('handleTitleChange', e.target.value);
};

watch(selectedColor, newVal => {
  const newColor = newVal?.hex ?? '#000';
  emit('handleColorChange', newColor);
});

function goToThankYou() {
  router.push('/thank-you');
}
</script>

<style scoped>
.title-color-card {
  --title-color-card-buy-button-display: flex;

  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  width: 100%;
  max-width: 320px;
  opacity: 0.8;
  box-sizing: border-box;
  margin: auto;
}

@media (max-width: 1200px) {
  .title-color-card {
    --title-color-card-buy-button-display: none;
  }
}

.title-color-card > * {
  width: 100%;
}

.title-color-card__title-input {
  width: 90%;
  margin-bottom: 20px;
  color: var(--input-text-color);
  padding: 0.875rem 0 0.875rem 1.875rem;
  margin-top: 5px;
  font-size: 0.875rem;
  border: 1px solid #ccc;
  border-radius: 50px;
  color: #06001c;
}

.title-color-card__buy-button {
  display: var(--title-color-card-buy-button-display);
  justify-content: center;
}

.color-picker-group {
  margin-bottom: 20px;
}

.title-color-card__custom-chrome-picker {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.title-color-card__custom-chrome-picker .vc-chrome-fields,
.title-color-card__custom-chrome-picker .vc-chrome-saturation {
  display: none !important;
}

.title-color-card__custom-chrome-picker .vc-chrome-controls {
  display: flex;
  justify-content: center;
}
</style>
