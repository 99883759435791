<template>
  <div class="behind">
    <div class="behind__nav">
      <LSHeader :navigate-to="goBack" :url="goBackUrl"/>
    </div>
    <div class="behind__container">
      <p class="behind__description">
        LiquidSound turns sound into scent.
      </p>
      <p class="behind__description">
        Your favorite melody, your voice, a cherished moment—reimagined as a fragrance you can wear.
      </p>
      <p class="behind__description">
        How? We pair musical notes with perfume notes. Highs become bright and sharp; lows feel warm and deep. <br/>
        It’s personal, it’s yours, and it changes how you perceive sound.
      </p>
      <p class="behind__description">
        We’re not just creating perfume—we’re pioneering a sensory fusion. <br/>
        Step in. Sense it. Discover what your sound smells like
      </p>
    </div>
  </div>
</template>

<script setup>
import LSHeader from '@/components/LSHeader.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const goBackUrl = '/main'

function goBack() {
  router.push(goBackUrl);
}

</script>

<style scoped>
.behind {
  --max-text-container-width: 66ch;
  --behind-font-size: 1.5rem;

  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: 100%;
  min-height: fit-content;
  margin: auto;
}

@media (max-width: 992px) {
  .behind {
    --max-text-container-width: 44ch;
    --behind-font-size: 1.125rem;
  }
}

.behind__nav {
  width: 100%;
  margin: auto unset;
}

.behind__container {
  max-width: var(--max-text-container-width);
  text-align: start;
  margin: auto;
  color: var(--text-color);
}

.behind__description {
  font-size: var(--behind-font-size);
}
</style>
