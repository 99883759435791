<template>
  <div class="breakdown">
    <LSHeader :navigate-to="goBack" :url="goBackUrl"/>

    <h1 class="breakdown__title">BREAKDOWN OF SCENTS</h1>

    <main class="breakdown__main">
      <div class="breakdown__notes-card">
        <NotesCard
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
          :baseNotes="baseNotes"
        />
      </div>

      <figure class="breakdown__bottle">
        <img class="breakdown__rotating-bottle" :src="butla" alt="Animated rotating bottle" >
      </figure>
      
      <div class="breakdown__customize">
        <MainButton class="breakdown__customize-btn" @click="goToCustomize">
            <span class="span-fade-in">CUSTOMIZE</span> <span class="span-fade-in">&nbsp;BOTTLE</span>
        </MainButton>
      </div>
    </main>
  </div>
</template>

<script setup>
import NotesCard from '../components/NotesCard.vue';
import MainButton from '../components/MainButton.vue';
import butla from '/public/movies/white.png';
import LSHeader from '@/components/LSHeader.vue';

import { useRouter } from 'vue-router';

const defaultData = {  name: 'Atchoo!',
  topNotes: ['Chestnut', 'Green Mandarin'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  baseNotes: ['Apple', 'Peach'],
  musicNotes: ['D4', 'F4', 'A2', 'C3', 'B1', 'F1'],
  price: 130,
  edition: '30/50',
  size: '50mm/1.7 fl oz',
  quantity: '01'} 

const {
  name,
  topNotes,
  middleNotes,
  baseNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
} = window.bottle ?? defaultData;

const router = useRouter();
function goToCustomize() {
  router.push('/customize');
}

const goBackUrl = '/analyze'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.breakdown {
  --breakdown-max-width: 70%;
  --breakdown-title-margin: auto auto 0 auto;
  --breakdown-title-font-size: 3.1vmax;
  --breakdown-main-direction: row;
  --breakdown-notes-card-margin: initial;
  --breakdown-notes-card-max-width: 500px;
  --breakdown-bottle-margin: initial;
  --breakdown-bottle-order: initial;
  --breakdown-rotating-bottle-width: 100%;

  text-align: center;
  padding: 24px;
  max-width: var(--breakdown-max-width);
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 95%;
}

@media (max-width: 1700px) {
  .breakdown {
    --breakdown-rotating-bottle-width: 80%;
  }
}

@media (max-width: 1500px) {
  .breakdown {
    --breakdown-title-margin: 20px auto;
    --breakdown-rotating-bottle-width: 60%;
  }
}

@media (max-width: 1200px) {
  .breakdown {
    --breakdown-title-font-size: 4.3vmax;
    --breakdown-main-direction: column;
    --breakdown-notes-card-max-width: 350px;
    --breakdown-notes-card-margin: 20px 0;
    --breakdown-max-width: 100%;
    --breakdown-bottle-margin: 20px 0;
    --breakdown-bottle-order: -1;
  }
}

@media (max-width: 992px) {
  .breakdown {
    --breakdown-title-font-size: 1.6vmax;
  }
}

.breakdown__title {
  font-size: var(--breakdown-title-font-size);
  margin: var(--breakdown-title-margin);
  text-wrap: nowrap;
  color: var(--title-text-color);
}

.breakdown__main {
  display: flex;
  flex-direction: var(--breakdown-main-direction);
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
}

.breakdown__notes-card {
  display: flex;
  margin: var(--breakdown-notes-card-margin); 
  max-width: var(--breakdown-notes-card-max-width);
  width: 100%;
}

.breakdown__bottle {
  margin: var(--breakdown-bottle-margin);
  order: var(--breakdown-bottle-order)
}

.breakdown__rotating-bottle {
  width: var(--breakdown-rotating-bottle-width);
  animation: fade-out 2s;
  max-width: 300px;
  height: auto;
}

.breakdown__customize-btn {
  text-wrap: nowrap;
  margin: auto auto 20px auto;
}

.breakdown__main > * {
  flex: 1;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
