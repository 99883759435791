<template>
  <button class="navigation-btn" @click="handleNavigation">
    <a :href="url" class="navigation-btn__link">
      <span v-if="!arrowHidden" class="navigation-btn__arrow">← </span>
      <span class="navigation-btn__text">{{ alternativeText ?? 'BACK' }}</span>
    </a>
  </button>
</template>

<script setup>
const { navigateTo, alternativeText, arrowHidden, url } = defineProps([
  'navigateTo',
  'alternativeText',
  'arrowHidden',
  'url',
]);

const handleNavigation = e => {
  navigateTo();
  e.preventDefault();
};
</script>

<style scoped>
.navigation-btn {
  --navigation-btn-text-last-child-display: initial;
  --navigation-btn-arrow-font-size: initial;
  --navigation-btn-text-font-size: initial;

  border: none;
  background: none;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .navigation-btn {
    --navigation-btn-text-last-child-display: none;
    --navigation-btn-arrow-font-size: 2.25rem;
    --navigation-btn-text-font-size: 0.75rem;
  }
}

.navigation-btn__link {
  text-wrap: nowrap;
  font-size: 1.125rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 999;
  text-decoration: none;
  color: unset;
}

.navigation-btn__arrow {
  font-size: var(--navigation-btn-arrow-font-size);
}

.navigation-btn__text {
  font-size: var(--navigation-btn-text-font-size);
}

.navigation-btn__text:last-child {
  height: 16px;
  display: var(--navigation-btn-text-last-child-display);
}
</style>
