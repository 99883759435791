export const noteToSmellMap = {
    'C0': { name: 'Cade Oil', type: 'top', weight: 1 },
    'C#0': { name: 'Charcoal', type: 'top', weight: 2 },
    'D0': { name: 'Guaiacwood', type: 'top', weight: 3 },
    'D#0': { name: 'Smoked Tea', type: 'top', weight: 4 },
    'E0': { name: 'Driftwood', type: 'top', weight: 5 },
    'F0': { name: 'Ozonic Accord', type: 'top', weight: 6 },
    'F#0': { name: 'Sea Salt', type: 'top', weight: 7 },
    'G0': { name: 'Iodine Accord', type: 'top', weight: 8 },
    'G#0': { name: 'Tar Accord', type: 'top', weight: 9 },
    'A0': { name: 'Charred Wood', type: 'top', weight: 10 },
    'A#0': { name: 'Gunpowder', type: 'top', weight: 11 },
    'B0': { name: 'Volcanic Ash', type: 'top', weight: 12 },

    'C1': { name: 'White Musk', type: 'top', weight: 13 },
    'C#1': { name: 'Civet Accord', type: 'top', weight: 14 },
    'D1': { name: 'Ambergris', type: 'top', weight: 15 },
    'D#1': { name: 'Suede', type: 'top', weight: 16 },
    'E1': { name: 'Castoreum Accord', type: 'top', weight: 17 },
    'F1': { name: 'Birch Tar', type: 'top', weight: 18 },
    'F#1': { name: 'Leather', type: 'top', weight: 19 },
    'G1': { name: 'Raw Tobacco', type: 'top', weight: 20 },
    'G#1': { name: 'Cumin', type: 'top', weight: 21 },
    'A1': { name: 'Cistus Labdanum', type: 'top', weight: 22 },
    'A#1': { name: 'Musk Accord', type: 'top', weight: 23 },
    'B1': { name: 'Animalic Amber', type: 'top', weight: 24 },

    'C2': { name: 'Vanilla', type: 'middle', weight: 25 },
    'C#2': { name: 'Almond', type: 'middle', weight: 26 },
    'D2': { name: 'Tonka Bean', type: 'middle', weight: 27 },
    'D#2': { name: 'Caramel', type: 'middle', weight: 28 },
    'E2': { name: 'Chocolate', type: 'middle', weight: 29 },
    'F2': { name: 'Coffee', type: 'middle', weight: 30 },
    'F#2': { name: 'Honey', type: 'middle', weight: 31 },
    'G2': { name: 'Praline', type: 'middle', weight: 32 },
    'G#2': { name: 'Molasses', type: 'middle', weight: 33 },
    'A2': { name: 'Maple Syrup', type: 'middle', weight: 34 },
    'A#2': { name: 'Nougat', type: 'middle', weight: 35 },
    'B2': { name: 'Brown Sugar', type: 'middle', weight: 36 },

    'C3': { name: 'Sandalwood', type: 'middle', weight: 37 },
    'C#3': { name: 'Cedarwood', type: 'middle', weight: 38 },
    'D3': { name: 'Vetiver', type: 'middle', weight: 39 },
    'D#3': { name: 'Patchouli', type: 'middle', weight: 40 },
    'E3': { name: 'Hinoki', type: 'middle', weight: 41 },
    'F3': { name: 'Oud', type: 'middle', weight: 42 },
    'F#3': { name: 'Cashmeran', type: 'middle', weight: 43 },
    'G3': { name: 'Myrrh', type: 'middle', weight: 44 },
    'G#3': { name: 'Frankincense', type: 'middle', weight: 45 },
    'A3': { name: 'Benzoin', type: 'middle', weight: 46 },
    'A#3': { name: 'Labdanum', type: 'middle', weight: 47 },
    'B3': { name: 'Tolu Balsam', type: 'middle', weight: 48 },

    'C4': { name: 'Black Pepper', type: 'middle', weight: 49 },
    'C#4': { name: 'Clove', type: 'middle', weight: 50 },
    'D4': { name: 'Cardamom', type: 'middle', weight: 51 },
    'D#4': { name: 'Cinnamon', type: 'middle', weight: 52 },
    'E4': { name: 'Nutmeg', type: 'middle', weight: 53 },
    'F4': { name: 'Pink Pepper', type: 'middle', weight: 54 },
    'F#4': { name: 'Saffron', type: 'middle', weight: 55 },
    'G4': { name: 'Ginger', type: 'middle', weight: 56 },
    'G#4': { name: 'Star Anise', type: 'middle', weight: 57 },
    'A4': { name: 'Allspice', type: 'middle', weight: 58 },
    'A#4': { name: 'Coriander', type: 'middle', weight: 59 },
    'B4': { name: 'Pimento', type: 'middle', weight: 60 },

    'C5': { name: 'Rose', type: 'base', weight: 61 },
    'C#5': { name: 'Jasmine', type: 'base', weight: 62 },
    'D5': { name: 'Tuberose', type: 'base', weight: 63 },
    'D#5': { name: 'Lily of the Valley', type: 'base', weight: 64 },
    'E5': { name: 'Peony', type: 'base', weight: 65 },
    'F5': { name: 'Ylang-Ylang', type: 'base', weight: 66 },
    'F#5': { name: 'Orange Blossom', type: 'base', weight: 67 },
    'G5': { name: 'Lavender', type: 'base', weight: 68 },
    'G#5': { name: 'Sage', type: 'base', weight: 69 },
    'A5': { name: 'Rosemary', type: 'base', weight: 70 },
    'A#5': { name: 'Eucalyptus', type: 'base', weight: 71 },
    'B5': { name: 'Clary Sage', type: 'base', weight: 72 },

    'C6': { name: 'Apple', type: 'base', weight: 73 },
    'C#6': { name: 'Peach', type: 'base', weight: 74 },
    'D6': { name: 'Blackcurrant', type: 'base', weight: 75 },
    'D#6': { name: 'Raspberry', type: 'base', weight: 76 },
    'E6': { name: 'Pineapple', type: 'base', weight: 77 },
    'F6': { name: 'Pear', type: 'base', weight: 78 },
    'F#6': { name: 'Plum', type: 'base', weight: 79 },
    'G6': { name: 'Green Apple', type: 'base', weight: 80 },
    'G#6': { name: 'Fig Leaf', type: 'base', weight: 81 },
    'A6': { name: 'Bamboo', type: 'base', weight: 82 },
    'A#6': { name: 'Basil', type: 'base', weight: 83 },
    'B6': { name: 'Galbanum', type: 'base', weight: 84 },

    'C7': { name: 'Bergamot', type: 'base', weight: 85 },
    'C#7': { name: 'Lemon', type: 'base', weight: 86 },
    'D7': { name: 'Orange', type: 'base', weight: 87 },
    'D#7': { name: 'Grapefruit', type: 'base', weight: 88 },
    'E7': { name: 'Yuzu', type: 'base', weight: 89 },
    'F7': { name: 'Mandarin', type: 'base', weight: 90 },
    'F#7': { name: 'Lime', type: 'base', weight: 91 },
    'G7': { name: 'Petitgrain', type: 'base', weight: 92 },
    'G#7': { name: 'Verbena', type: 'base', weight: 93 },
    'A7': { name: 'Neroli', type: 'base', weight: 94 },
    'A#7': { name: 'Mint', type: 'base', weight: 95 },
    'B7': { name: 'Lemongrass', type: 'base', weight: 96 },

    'C8': { name: 'Fresh Air Accord', type: 'base', weight: 97 },
    'C#8': { name: 'Ocean Spray', type: 'base', weight: 98 },
    'D8': { name: 'White Lotus', type: 'base', weight: 99 },
    'D#8': { name: 'Ice Accord', type: 'base', weight: 100 },
    'E8': { name: 'Snowdrops', type: 'base', weight: 101 },
    'F8': { name: 'Rain Mist', type: 'base', weight: 102 },
    'F#8': { name: 'Mountain Water', type: 'base', weight: 103 },
    'G8': { name: 'Aldehydes', type: 'base', weight: 104 },
    'G#8': { name: 'Cotton Musk', type: 'base', weight: 105 },
    'A8': { name: 'Crisp Linen', type: 'base', weight: 106 },
    'A#8': { name: 'Silver Birch', type: 'base', weight: 107 },
    'B8': { name: 'Morning Dew', type: 'base', weight: 108 },

    'C9': { name: 'Animalic', type: 'base', weight: 109 },
    'C#9': { name: 'Orris Root', type: 'base', weight: 110 },
    'D9': { name: 'Benzoin Resin', type: 'base', weight: 111 },
    'D#9': { name: 'Birch Smoke', type: 'base', weight: 112 },
    'E9': { name: 'Seaweed', type: 'base', weight: 113 },
    'F9': { name: 'Salted Caramel', type: 'base', weight: 114 },
    'F#9': { name: 'Cocoa Butter', type: 'base', weight: 115 },
    'G9': { name: 'Smoked Wood', type: 'base', weight: 116 },
    'G#9': { name: 'Fir Balsam', type: 'base', weight: 117 },
    'A9': { name: 'Whiskey', type: 'base', weight: 118 },
    'A#9': { name: 'Bourbon', type: 'base', weight: 119 },
    'B9': { name: 'Shiso Leaf', type: 'base', weight: 120 }
};
