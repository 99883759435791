<template>
  <main class="liquefy">
    <div class="liquefy__wave">
      <Wave size="md" :no-margin="true" />
    </div>

    <section class="liquefy__progress">
      <div class="liquefy__numbers">
        <div class="liquefy__odometer">{{ percentage }}</div>
      </div>
      <SoundWave style="margin: 4% 0" :variant="getSoundWaveVariant()" />
      <p class="liquefy__status">LIQUEFYING ...</p>
    </section>

    <p class="liquefy__footer">
      To upload music, let's collaborate responsibly. Ensure all licenses are in
      order before applying. Join our creative community and apply today to
      start transforming sound into scents.
    </p>
  </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import SoundWave from '../components/SoundWaves.vue';
import Wave from '../components/Wave.vue';
import { useAudioStore } from '@/store/audioStore';
import { startAnalysis } from '@/utils/AudioProcessing';
import Odometer from 'odometer/odometer.js';
import 'odometer/themes/odometer-theme-default.css';

const router = useRouter();
const percentage = ref(0);
const audioStore = useAudioStore();
const variant = ref(getSoundWaveVariant())

// fetch(localAudioFile)
//     .then(response => response.blob())
//     .then(blob => {
//       return startAnalysis(blob)
//     })
//     .catch(error => console.error('Error loading audio file:', error));

startAnalysis(audioStore.getAudioData);

function getSoundWaveVariant() {
  const mobileDevice = 576;
  const mediumVariant = 'md';
  const largeVariant = 'lg';

  return window.innerWidth <= mobileDevice ? largeVariant : mediumVariant 
}

function setSoundWaveVariant() {
  variant.value = getSoundWaveVariant()
}

onMounted(() => {
  window.onresize = setSoundWaveVariant;

  const odometer = new Odometer({
    el: document.querySelector('.liquefy__odometer'),
  });

  setTimeout(() => {
    odometer.update(99);
  }, 600);
  setTimeout(() => odometer.update(100), 2750);
  setTimeout(() => {
    router.push('/scent-breakdown');
  }, 5000);
});


onUnmounted(() => {
  window.onresize = null;
})

</script>

<style scoped>
.liquefy {
  --liquefy-max-width: 70%;
  --liquefy-font-size-footer: 1rem;
  --liquefy-margin-footer: auto auto 50px; 
  --liquefy-padding: 24px; 

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: var(--liquefy-padding);
  box-sizing: border-box;
  max-width: var(--liquefy-max-width);
  margin: auto;
  color: var(--text-color);
}

@media (max-width: 992px) {
  .liquefy {
    --liquefy-max-width: 100%;
    --liquefy-font-size-footer: 0.75rem; 
    --liquefy-margin-footer: auto auto 10px; 
  }
}

@media (max-width: 576px) {
  .liquefy {
    --liquefy-padding: 12px; 
  }
}

.liquefy__wave {
  margin: 0 auto auto;
}

.liquefy__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.liquefy__numbers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.liquefy__odometer {
  opacity: 1 !important;
  font-size: 1.5rem;
  margin-left: -1px;
  font-family: OutfitLight, sans-serif;
}

.liquefy__status {
  font-size: 1.25rem;
  letter-spacing: 0.1em;
}

.liquefy__footer {
  font-size: var(--liquefy-font-size-footer);
  max-width: 500px;
  margin: var(--liquefy-margin-footer);
}
</style>
