import { hertzToNoteMap } from '../assets/hertzToNoteMap';
import { noteToSmellMap } from '../assets/noteToSmellMap';

let analysisInterval;
let intervalSmellMap = {};
let totalOccurrences = 0;

const AMOUNT_OF_NOTES = 6;
const VOICE_MIN_FREQUENCY = 30;
const VOICE_MAX_FREQUENCY = 9999;
const ANALYSIS_INTERVAL_MS = 20;
const NOISE_THRESHOLD = 90;

const TOP_NOTE = 'top';
const MIDDLE_NOTE = 'middle';
const BASE_NOTE = 'base';

export function startAnalysis(audioBlob) {
    if (!audioBlob) {
        alert('No audio data available for analysis.');
        return;
    }

    const audioContext = new window.AudioContext();
    const reader = new FileReader();

    reader.onload = function (e) {
        audioContext.decodeAudioData(e.target.result, function (buffer) {
            analyzeAudio(buffer, audioContext.sampleRate);
        });
    };

    reader.readAsArrayBuffer(audioBlob);
}

function analyzeAudio(audioBuffer, sampleRate) {
    const chunkSizeInSeconds = 3;
    const audioDuration = audioBuffer.duration;
    let currentTime = 0;
    let chunksProcessed = 0;
    const totalChunks = Math.ceil(audioDuration / chunkSizeInSeconds);
    const audioContext = new window.AudioContext();

    function handleFinalization() {
        Object.keys(intervalSmellMap).forEach((key) => {
            if (intervalSmellMap[key]) {
                intervalSmellMap[key].percentage = intervalSmellMap[key]?.occurrences / totalOccurrences;
            }
        });

        clearInterval(analysisInterval);
        const notes = getMostPrevalentNotes(AMOUNT_OF_NOTES);
        const redistributedNotes = redistributeNotes(notes);
        // console.log(redistributedNotes);
        // const categorizedNotes = allocateByPercentage(notes.map(musicalNoteData => musicalNoteData.note));
        // const smells = getMostPrevalentSmells(categorizedNotes);
        const topSmells = redistributedNotes[TOP_NOTE].map(noteData => noteData.smell);
        const middleSmells = redistributedNotes[MIDDLE_NOTE].map(noteData => noteData.smell);
        const baseSmells = redistributedNotes[BASE_NOTE].map(noteData => noteData.smell);
        const musicNotes = [...redistributedNotes[TOP_NOTE].map(noteData => noteData.note), ...redistributedNotes[MIDDLE_NOTE].map(noteData => noteData.note), ...redistributedNotes[BASE_NOTE].map(noteData => noteData.note)]
        const bottle = {
            name: 'Shibuya Crossing',
            topNotes: topSmells,
            middleNotes: middleSmells,
            baseNotes: baseSmells,
            musicNotes,
            price: 130,
            edition: '30/50',
            size: '50mm/1.7 fl oz',
            quantity: '01'
        };

        window.bottle = bottle;
        intervalSmellMap = {};
        totalOccurrences = 0;
    }

    while (currentTime < audioDuration) {
        const startOffset = currentTime;
        const endOffset = Math.min(currentTime + chunkSizeInSeconds, audioDuration);

        const source = audioContext.createBufferSource();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.buffer = audioBuffer;
        source.connect(analyser);

        source.start(0, startOffset, endOffset - startOffset);

        analysisInterval = setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            const hertz = getDominantFrequency(dataArray, sampleRate);

            if (hertz < VOICE_MIN_FREQUENCY || hertz > VOICE_MAX_FREQUENCY) return;
            if (Math.max(...dataArray) < NOISE_THRESHOLD) return;

            const note = convertHertzToNote(hertz);
            const smell = convertNoteToSmell(note);

            if (!intervalSmellMap.hasOwnProperty(note)) {
                intervalSmellMap[note] = { occurrences: 0, smells: [], note, percentage: 0 };
            }

            intervalSmellMap[note].occurrences += 1;
            totalOccurrences += 1;
            intervalSmellMap[note].percentage = intervalSmellMap[note].occurrences / totalOccurrences;
            intervalSmellMap[note].smells.push(smell);
        }, ANALYSIS_INTERVAL_MS);

        source.onended = function () {
            clearInterval(analysisInterval);
            chunksProcessed += 1;

            if (chunksProcessed === totalChunks) {
                handleFinalization();
            }
        };

        currentTime += chunkSizeInSeconds;
    }
}

function getDominantFrequency(dataArray, sampleRate) {
    const peakBin = dataArray.indexOf(Math.max(...dataArray));
    return (peakBin / dataArray.length) * (sampleRate / 2);
}

function getNoteType(note) {
    const integerRegex = /-?\d+/g;
    const noteType = note.match(integerRegex)[0];
    if (noteType >= 5) {
        return BASE_NOTE;
    } else if (noteType >= 2) {
        return MIDDLE_NOTE;
    } else {
        return TOP_NOTE;
    }
}

function redistributeNotes(notes) {
    const transformedNotes = notes.map(noteData => ({
        ...noteData,
        weight: noteToSmellMap[noteData.note].weight,
        type: noteToSmellMap[noteData.note].type,
        smell: noteToSmellMap[noteData.note].name
    }));

    // Sort notes by weight (lightest to heaviest)
    const sortedNotes = transformedNotes.sort((a, b) => a.weight - b.weight);

    const redistributedNotes = {
        [TOP_NOTE]: [],
        [MIDDLE_NOTE]: [],
        [BASE_NOTE]: [],
    };

    const total = sortedNotes.length;
    if (total <= 5) {
        sortedNotes.forEach((note, i) => {
            const currentNotes = redistributedNotes[sortedNotes[i].type];
            // console.log(currentNotes, currentNotes.length === 2, currentNotes.length);
            if (currentNotes.length === 2) {
                // console.log(redistributedNotes.top < 2, redistributedNotes.middle < 2, redistributedNotes.base < 2);
                if (redistributedNotes.top.length < 2) {
                    redistributedNotes.top.push(sortedNotes[i]);
                    return;
                }

                if (redistributedNotes.middle.length < 2) {
                    redistributedNotes.middle.push(sortedNotes[i]);
                    return;
                }

                if (redistributedNotes.base.length < 2) {
                    redistributedNotes.base.push(sortedNotes[i]);
                    return;
                }
            } else {
                currentNotes.push(sortedNotes[i]);
            }
        });
    } else {
        redistributedNotes[TOP_NOTE] = sortedNotes.slice(0, 2);
        redistributedNotes[MIDDLE_NOTE] = sortedNotes.slice(2, 4);
        redistributedNotes[BASE_NOTE] = sortedNotes.slice(-2);
    }
    // console.log(sortedNotes);


    return redistributedNotes;
}

function convertHertzToNote(hertz) {
    let closestNote = null;
    let minDifference = Infinity;

    for (const freq in hertzToNoteMap) {
        const frequency = parseFloat(freq);
        const difference = Math.abs(hertz - frequency);

        if (difference < minDifference) {
            minDifference = difference;
            closestNote = hertzToNoteMap[freq];
        }
    }

    return closestNote;
}

function convertNoteToSmell(note) {
    return noteToSmellMap[note].name || 'Unknown';
}

function getMostPrevalentNotes(amount) {
    const noteArray = Object.keys(intervalSmellMap).map((key) => intervalSmellMap[key]);
    noteArray.sort((a, b) => b.occurrences - a.occurrences);
    return noteArray.slice(0, amount).map(noteData => ({ note: noteData.note, percentage: noteData.occurrences }));
}

function allocateByPercentage(notes) {
    const total = notes.length;
    const sortedNotes = [...notes].sort((a, b) => a.localeCompare(b));
    // console.log(sortedNotes);

    const topLimit = Math.ceil(total * 0.35);
    const middleStart = Math.ceil(total * 0.25);
    const middleEnd = Math.ceil(total * 0.75);
    const baseStart = Math.ceil(total * 0.7);

    const topNotes = sortedNotes.slice(0, topLimit);
    const middleNotes = sortedNotes.slice(middleStart, middleEnd);
    const baseNotes = sortedNotes.slice(baseStart);

    return { topNotes, middleNotes, baseNotes };
}

function getMostPrevalentSmells(categorizedNotes) {
    const mapToSmell = (notes) => notes.map(note => convertNoteToSmell(note));

    return {
        Top: mapToSmell(categorizedNotes.topNotes),
        Middle: mapToSmell(categorizedNotes.middleNotes),
        Base: mapToSmell(categorizedNotes.baseNotes)
    };
}
