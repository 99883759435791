<template>
  <div @keypress.enter="goToPayment" @keypress.space="goToPayment" class="bottle" @click="goToPayment">
    <img class="bottle__video" :src="bottle?.movie || butla" alt="">
    <h2 class="bottle__heading">{{ bottle.name }}</h2>
  </div>
</template>

<script setup>
import butla from '/public/movies/bottle.mp4';
import { useRouter } from 'vue-router';
import { useBottleStore } from '../store/bottleStore';

const router = useRouter();
const bottleStore = useBottleStore();

const { bottle } = defineProps({
  bottle: {
    required: true,
    type: Object,
  },
});

function goToPayment() {
  bottleStore.setBottleData(bottle);
  router.push('/payment');
}
</script>

<style scoped>
.bottle {
  --bottle-margin-block-end: initial;
  margin-block-end: var(--bottle-margin-block-end);

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: auto;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

@media (max-width: 992px) {
  .bottle {
    --bottle-margin-block-end: 56px;
  }
}

.bottle__video {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.bottle__heading {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
