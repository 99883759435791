<template>
  <div class="ls-logo" role="banner" aria-label="Animated logo of liquid sound" >
    <img :class="`ls-logo__video ${getModifierClass()} ${getSizeClass()}`" :src="Wave" alt="Animated logo of liquid sound">
  </div>
</template>

<script setup>
import Wave from '/public/movies/logoAnim.png';
const { noMargin, size } = defineProps({
  noMargin: {
    type: Boolean,
    default: false,
    required: false,
  },
  size: {
    type: String,
    default: '',
    required: false,
  }
})

const getModifierClass = () => {
  const noMarginModifier = 'ls-logo__video--no-margin';
  return noMargin ? noMarginModifier : '';
}

const getSizeClass = () => {
  switch (size) {
    case 'sm':
        return 'ls-logo__video--sm';
    case 'md':
      return 'ls-logo__video--md'
  }

  return '';
}

</script>

<style scoped>
.ls-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  animation: ls-logo-fade-out 1s;
}

.ls-logo__video {
  margin-right: 55px;
}

.ls-logo__video--sm {
  max-width: 180px;
}

.ls-logo__video--md {
  max-width: 300px;
}

.ls-logo__video--no-margin {
  margin-right: 0;
}

@keyframes ls-logo-fade-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
