<template>
  <div class="product">

    <LSHeader :navCallback="stopSound" :navigate-to="goBack" :url="goBackUrl"/>
    <Overlay :visible="storeSubscriptionModalOpen" @close="closeStoreSubscriptionModal" v-show="storeSubscriptionModalOpen">
      <PreOrderModal @close="closeStoreSubscriptionModal"/>
    </Overlay>
    <h1 class="product__title">
      <span class="span-fade-in">{{ name }}</span>
    </h1>

    <main class="product__main">
      <div class="product__note-description">
        <NoteDisplay
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
          :baseNotes="baseNotes"
        />
        <div class="product__desktop-sound-button">
          <PlaySoundBtn @click="playSound" />
        </div>
      </div>

      <img class="product__bottle" :src="movie || butla" alt="Perfume bottle">

      <div class="product__perfume-description">
        <DetailsDisplay
          :edition="edition"
          :quantity="quantity"  
          :size="size"
        />
        <div class="product__desktop-payment-button">
          <PaymentBtn :price="price" @click="openStoreSubscriptionModal" />
        </div>
      </div>

      <div class="product__mobile-sound-button">
        <PlaySoundBtn @click="playSound"  />
      </div>

      <div class="product__mobile-payment-button">
        <PaymentBtn @click="openStoreSubscriptionModal" :price="price" />
      </div>
    </main>

  </div>
</template>

<script setup>
import { useBottleStore } from '../store/bottleStore';
import { useRouter } from 'vue-router';
import NoteDisplay from '../components/NotesCard.vue';
import DetailsDisplay from '../components/DetailsCard.vue';
import butla from '/public/movies/white.png';
import PlaySoundBtn from '@/components/PlaySoundBtn.vue';
import PaymentBtn from '@/components/PaymentBtn.vue';
import jung from '../assets/songs/jung.mp3';
import LSHeader from '@/components/LSHeader.vue';
import Overlay from '../components/Overlay.vue';
import PreOrderModal from '@/components/PreOrderModal.vue';
import { ref } from 'vue';

const bottleStore = useBottleStore();

const defaultData = {
  name: 'Concrete Jungle!',
  topNotes: ['Chestnut'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  baseNotes: ['Vanilla'],
  musicNotes: ['D4', 'F4', 'C5', 'A2'],
  price: 130,
  edition: '01/1000',
  size: '50mm/1.7 fl oz',
  quantity: '01',
  song: jung,
};

const {
  name,
  topNotes,
  middleNotes,
  baseNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
  song,
  movie,
} = bottleStore.getBottleData ?? defaultData;
const router = useRouter();
const audio = new Audio(song ?? atchoo);
const storeSubscriptionModalOpen = ref(false);
function playSound() {
  audio.play();
}

function stopSound() {
  audio.pause();
}

function openStoreSubscriptionModal() {
  storeSubscriptionModalOpen.value = true;
  // console.log(storeSubscriptionModalOpen.value)
}

function closeStoreSubscriptionModal() {
  storeSubscriptionModalOpen.value = false;
}

const goBackUrl = '/playlist'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.product {
  --product-height: 95%;
  --product-max-width: 70%;

  --product-title-margin: auto;
  --product-title-font-size: 4rem;

  --product-bottle-order: unset;

  --product-description-margin: unset;

  --product-main-dir: row;
  --product-main-max-width: unset;

  --product-desktop-btn-display: block;
  --product-mobile-btn-display: none;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--product-height);
  min-height: fit-content;
  max-width: var(--product-max-width);
  margin: auto;
  padding: 24px;
}

@media (max-width: 1200px) {
  .product {
    --product-height: auto;

    --product-title-margin: 60px 0;
  }
}

@media (max-width: 992px) {
  .product {
    --product-bottle-order: -1;

    --product-description-margin: 0 0 20px;

    --product-title-font-size: 2.25rem;

    --product-main-dir: column;
    --product-main-max-width: 100%;

    --product-desktop-btn-display: none;
    --product-mobile-btn-display: flex;
  }
}

@media (max-width: 576px) {
  .product {
    --product-max-width: 100%;
  }
}

.product__mobile-payment-button,
.product__mobile-sound-button {
  display: var(--product-mobile-btn-display);
  max-width: 350px;
  width: 100%;
}

.product__desktop-sound-button,
.product__desktop-payment-button {
  width: 100%;
  display: var(--product-desktop-btn-display);
}

.product__main {
  display: flex;
  flex-direction: var(--product-main-dir);
  max-width: var(--product-main-max-width);
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  width: 100%;
  margin: 0 auto auto auto;
  flex-wrap: wrap;
}

.product__main > * {
  flex: 1;
}

.product__note-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  margin: var(--product-description-margin);
  animation: show-content 2s;
}

.product__bottle {
  height: auto;
  animation: fade-out 2s;
  width: 70%;
  max-width: 300px;
  margin-bottom: 80px;
  min-width: 150px;
  order: var(--product-bottle-order);
}

.product__perfume-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  margin: var(--product-description-margin);
  animation: show-content 2s;
}

.product__title {
  font-weight: 600;
  letter-spacing: 0.05em;
  
  margin: var(--product-title-margin);
  font-size: var(--product-title-font-size);
  margin-bottom: 5%;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--title-text-color);
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes show-content {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
</style>
