<template>
  <div class="thank-you">
    <div class="thank-you__wave">
      <Wave size="sm" :no-margin="true" />
    </div>

    <main class="thank-you__main">
      <form @submit.prevent="sendEmail" class="thank-you__form">
        <h1 class="thank-you__title">Subscribe to our newsletter!</h1>
        <input
          placeholder="Your email"
          v-model="formText"
          class="thank-you__email-input"
          type="email"
        />

        <div class="thank-you__subscribe-btn">
          <MainButton :full-width="true">
            <span class="thank-you__subscription-btn-text span-fade-in">
              Subscribe
            </span>
          </MainButton>
        </div>
      </form>
    </main>

    <div class="thank-you__back-btn">
      <MainButton variant="outline" :full-width="true" @click="backToMain">
        <span class="thank-you__back-btn-text span-fade-in">
          BACK TO MAIN
        </span>
      </MainButton>
    </div>
  </div>
</template>

<script setup>
import Wave from '../components/Wave.vue';
import { useRouter } from 'vue-router';
import MainButton from '../components/MainButton.vue';
import { ref } from 'vue';
import emailjs from '@emailjs/browser';

const router = useRouter();

const backToMain = () => {
  router.push('/');
};

const formText = ref('');

const sendEmail = () => {
  const serviceID = 'service_2hyma5s';
  const templateID = 'template_v6ttylo';
  const userID = 'wIweFqJqqcdrC7OkG';

  const templateParams = {
    message: formText.value,
  };

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then(response => {
      formText.value = '';
      alert(`You've subscribed to our newsletter!`);
    })
    .catch(error => {
      console.error('Error sending feedback:', error);
      alert('Failed to send feedback. Please try again.');
    });
};
</script>

<style scoped>
.thank-you {
  --thank-you-main-margin: 0 auto auto auto;
  --thank-you-back-btn-margin: initial;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 70%;
  margin: auto;
}

@media (max-width: 992px) {
  .thank-you {
    --thank-you-main-margin: 0;
    --thank-you-back-btn-margin: auto 0 20%;
  }
}

.thank-you__wave {
  margin: 0 auto auto;
}

.thank-you__main {
  margin: var(--thank-you-main-margin);
}

.thank-you__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20% 0 5%;
}

.thank-you__title {
  font-size: 3.2vmax;
  font-weight: 400;
  color: white;
  letter-spacing: 0.05em;
  margin: auto;
  text-wrap: nowrap;
}

.thank-you__email-input {
  width: 250px;
  margin: 5px 20px 20px;
  color: var(--input-text-color);
  padding: 0.875rem 0 0.875rem 1.875rem;
  font-size: 0.875rem;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.thank-you__subscribe-btn {
  font-size: 1.125rem;
  margin: auto;
  width: 250px;
}

.thank-you__back-btn-text,
.thank-you__subscription-btn-text {
  position: relative;
  font-family: OutfitLight, sans-serif;
  font-weight: 400;
  z-index: 99999;
  margin: 0;
  letter-spacing: 2px;
  font-size: 1.125rem;
}

.thank-you__back-btn {
  width: 250px;
  margin: var(--thank-you-back-btn-margin);
}
</style>
