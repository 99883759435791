<template>
  <div class="customize">
    <LSHeader :navigateTo="goBack" :url="goBackUrl" />

    <main class="customize__main">
      <h1 class="customize__title">
        <span class="span-fade-in">&nbsp;CUSTOMIZE</span>
        <span class="span-fade-in">&nbsp;YOUR</span>
        <span class="span-fade-in">&nbsp;LIQUID</span>
        <span class="span-fade-in">&nbsp;SOUND</span>
      </h1>

      <div class="customize__content">
        <div class="customize__options-card">
          <BottleOptionsCard @update:selectedCombination="handleSelection" />
        </div>

        <div class="customize__bottle">
          <img
            :src="currentBottle"
            class="customize__bottle-img"
            alt="Bottle"
          />
          <div class="">
            <span
              class="customize__bottle-text span-fade-in"
              :style="{ color: nameColor }"
              >{{ bottleTitle }}</span
            >
          </div>
          <img
            class="customize__bottle-logo"
            :src="logo"
            alt="liquid sound logo"
          />
        </div>

        <div class="customize__color-card">
          <TitleAndColorCard
            @handleColorChange="handleColorChange"
            @handleTitleChange="handleTitleChange"
          />
        </div>

        <div class="customize__purchase-btn">
          <MainButton @click="goToThankYou">
            <span class="span-fade-in">PURCHASE</span>
            <span class="span-fade-in">&nbsp;NOW</span>
          </MainButton>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import BottleOptionsCard from '../components/BottleOptionsCard.vue';
import TitleAndColorCard from '../components/TitleAndColorCard.vue';
import butla from '../assets/images/bottleVariants/whiteSilver.png';
import logo from '../assets/images/logox1.png';
import MainButton from '../components/MainButton.vue';
import LSHeader from '@/components/LSHeader.vue';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

const bottleTitle = ref('Name Your Fragrance');
const nameColor = ref('#000');
const router = useRouter();
const currentBottle = ref(butla);
const handleTitleChange = newTitle => {
  bottleTitle.value = newTitle;
};

const goBackUrl = '/scent-breakdown';

function handleSelection(path) {
  // console.log('Selected Bottle:', path);
  currentBottle.value = path;
}

function goToThankYou() {
  router.push('/thank-you');
}

const handleColorChange = newColor => {
  nameColor.value = newColor;
};

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.customize {
  /** animations */
  --customize-anim-transform-start: translateY(150%);
  --customize-anim-transform-end: translateY(0%);
  --customize-anim-opacity-start: 0;
  --customize-anim-opacity-end: 1;
  --customize-anim-width-start: 0%;
  --customize-anim-width-end: 100%;

  --customize-height: 100%;
  --customize-max-width: 70%;

  --customize-content-flex-dir: row;

  --customize-bottle-order: unset;
  --customize-bottle-width: 70%;
  --customize-bottle-margin: unset;
  --customize-bottle-text: unset;

  --customize-color-card-order: unset;
  --customize-color-card-margin: unset;

  --customize-purchase-btn-display: none;
  --customize-purchase-btn-margin: 1.25rem auto;
  --customize-purchase-btn-padding: 0.875rem 2.25rem;

  --customize-title-font-size: 2.25rem;
  --customize-title-margin: auto auto 40px auto;

  text-align: center;
  padding: 10px 24px;
  max-width: var(--customize-max-width);
  display: flex;
  flex-direction: column;
  margin: auto;
  height: var(--customize-height);
  min-height: fit-content;
  overflow: hidden;
  box-sizing: border-box;
}

@keyframes slide-in {
  0% {
    transform: var(--customize-anim-transform-start);
    opacity: var(--customize-anim-opacity-start);
    width: var(--customize-anim-width-start);
  }

  50% {
    opacity: var(--customize-anim-opacity-start);
    width: var(--customize-anim-width-start);
  }

  100% {
    transform: var(--customize-anim-transform-end);
    width: var(--customize-anim-width-end);
    opacity: var(--customize-anim-opacity-end);
  }
}

@media (max-width: 1200px) {
  .customize {
    --customize-anim-width-end: 50%;

    --customize-max-width: 100%;
    --customize-height: auto;

    --customize-content-flex-dir: column;

    --customize-bottle-order: -1;
    --customize-bottle-width: 50%;

    --customize-color-card-order: -1;
    --customize-color-card-margin: auto auto 20px auto;

    --customize-purchase-btn-display: block;
    --customize-purchase-btn-margin: 20px auto;
    --customize-purchase-btn-padding: 14px 36px;
  }
}

@media (max-width: 992px) {
  .customize {
    --customize-anim-width-end: 70%;

    --customize-bottle-width: 70%;
    --customize-bottle-margin: 20px 0;

    --customize-title-font-size: 3.5vmax;
    --customize-title-margin: 30px 0;
  }
}

@media (max-width: 576px) {
  .customize {
    --customize-anim-width-end: 80%;
    --customize-bottle-width: 80%;

    --customize-title-font-size: 1.9vmax;
    --customize-title-margin: 30px 0;
    --customize-bottle-text: 16px;
  }
}

.customize__main {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.customize__title {
  font-size: var(--customize-title-font-size);
  margin: var(--customize-title-margin);
  letter-spacing: 0.05em;
  text-wrap: nowrap;
  color: var(--title-text-color);
}

.customize__color-card {
  order: var(--customize-color-card-order);
  width: 100%;
  max-width: 300px;
  margin: var(--customize-color-card-margin);
}

.customize__content {
  display: flex;
  justify-content: space-between;
  flex-direction: var(--customize-content-flex-dir);
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 5%;
}

.customize__content > * {
  flex: 1;
}

.customize__options-card,
.customize__color-card {
  max-width: 300px;
  animation: show-in 1s;
}

.customize__bottle {
  max-width: 375px;
  position: relative;
  animation: slide-in 2s;
  order: var(--customize-bottle-order);
  width: var(--customize-bottle-width);
  margin: var(--customize-bottle-margin);
  width: 70%;
}

.customize__bottle-img {
  width: 100%;
  height: auto;
  animation: resize-width 2s;
}

.customize__bottle-text {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-family: OutfitLight, sans-serif;
  font-weight: 100;
  font-size: 1.25rem;
  word-break: break-word;
  font-size: var(--customize-bottle-text);
}

.customize__bottle-logo {
  position: absolute;
  bottom: 45%;
  left: 50%;
  width: 70% !important;
  mix-blend-mode: multiply;
  transform: translate(-50%, 50%);
  animation: slide-in-logo 2s;
}

.customize__purchase-btn {
  display: var(--customize-purchase-btn-display);
  margin: var(--customize-purchase-btn-padding);
  padding: var(--customize-purchase-btn-padding);
}

@keyframes slide-in-logo {
  0% {
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 0;
    width: 0%;
  }

  100% {
    width: 20%;
    opacity: 1;
  }
}

@keyframes resize-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes show-in {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
